footer{
	padding: 50px 0 35px 0;
	
	.footer-nav{
		ul{
			li{
				width: 100%;
				display: inline-block;
				padding: 15px 0;
				font-weight: 700;
				font-size: 16px;
				
				a{
					color: $base-color;
					text-decoration: none;
					
					img{
						margin: 0 10px 0 0;
					}
					
					&:hover{
						color: $base-color;
						text-decoration: underline;
					}
				}
			}
		}
	}
	
	.footer-logo{
		padding: 40px 0 0 0;
		
		img{
			max-height: 29px;
			margin: 0 auto;
			display: block;
		}
	}
}

@media screen and (min-width: 768px){
	footer{
		padding: 78px 0 35px 0;
		.footer-nav{
			ul{
				li{
					width: auto;
					padding: 0 20px;
				}
			}
		}
	}
}