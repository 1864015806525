@import 'variable';
@import 'mixins';

.adview-container{
	background: url("#{$images-path}/pink-bg.png") no-repeat left top;

	.adview-top{
		@include lg() {
			padding: 0 0 50px;
		}
	}

	.funeral-information{
		border: 1px solid #EAEAEA;
		border-radius: 32px;
		margin: 37px 0 0 0;
		padding: 40px 15px 60px;

		.funeral-information-title{
			background: url("#{$images-path}/icon-info.png") no-repeat left top;
			background-size: 60px;
			padding: 12px 0 12px 75px;
			font-weight: 600;
			font-size: 30px;
			line-height: 38px;
		}

		.funeral-info-data{
			padding: 28px 0 0 0;

			p{
				line-height: 31px;
				text-shadow: 0 3px 3px rgba(0,0,0,0.2);
				text-align: left;
				font-size: 20px;
				padding: 0 0 30px;
				margin: 0;
			}
		}
	}

	.info-map{
		padding: 17px 0 0;
	}

	.ad-view-left-info{
		padding: 40px 15px 60px;
		border: 5px solid $white-color;
		border-radius: 64px;
		box-shadow: 0 4px 4px rgba(0,0,0,0.25);
		margin: 15px 0 0 0;

		@include lg() {
			padding: 40px 40px 60px;
			margin: 25px 0 0 0;
		}

		ul{
			li{
				width: 100%;
				float: left;
				padding: 30px 0 0 0;

				.large-text{
					font-weight: 700;
					font-size: 26px;
					line-height: 50px;
				}
			}
		}
	}
}