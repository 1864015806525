@import 'variable';
@import 'mixins';

.box-data-row{
	.latest-ad-box{
		border: 1px solid #eaeaea;
		border-radius: 32px;
		margin: 36px 0 0 0;

		@include md() {
			margin: 46px 0 0 0;
		}
	}
	
	.ad-box-img{
		img{
			border-radius: 32px 32px 0 0;
			width: 100%;
		}		
	}
	
	.ad-box-bottom{
		padding: 24px 24px 48px;
	}
	
	.ad-box-year{
		font-size: 14px;
		color: #6D6D78;
	}
	
	h2{
		font-size: 20px;
		padding: 15px 0 0;
		
		a{
			color: $base-color;
			text-decoration: none;
			
			&:hover{
				color: #6D6D78;
			}
			
		}
	}
	
	.city-name{
		color: #6D6D78;
		padding: 10px 0 0;
		font-size: 14px;
		
		a{
			color: #6D6D78;
			text-decoration: none;
			
			&:hover{
				color: $base-color;
			}
		}
	}
}

.faq-section{
	padding: 104px 0 0 0;

	@include lg() {
		padding: 104px 0 0 0;
	}
	
	h3{
		text-align: center;

		@include lg() {
			text-align: right;
		}
	}
	
	.faq-img{

		padding: 109px 0 0 0;


		img{
			max-width: 100%;
			margin: 0 auto;
			display: block;

			@include lg() {
				float: right;
				max-width: 100%;
			}
		}
	}
	
	.accordion{
		padding: 40px 0 0 0;

		@include lg() {
			padding: 0;
		}
	}
}

.box-testimonial {
	padding: 93px 10px 0 10px;

	.grey-row {
		background: #f9f9f9;
		height: 250px;
	}
}