@import "variable";
@import "mixins";

.blog-data-row{
	padding: 0 0 40px;
	
	.blog-box{
		padding: 45px 0 0;
	}
	
	.blog-img{
		img{
			border-radius: 17px;
			width: 100%;
		}
	}
	
	.blog-info{
		color: #aaa;
		padding: 20px 0 0 0;
		
		a{
			color: #aaa;
			text-decoration: none;
			
			&:hover{
				color: $base-color;
				text-decoration: none;
			}
		}
	}
	
	h2{
		padding: 40px 0 0 0;
		font-size: 24px;
		font-weight: 500;
		max-width: 75%;
		margin: 0;
		
		a{
			color: $base-color;
			text-decoration: none;
			
			&:hover{
				color: $blue-color;
				text-decoration: none;
			}
		}
	}
	
	p{	width: 100%;
		float: left;
		padding: 15px 0;
		font-size: 16px;
		line-height: 24px;
	}
}

@media screen and (min-width: 1200px){
	.blog-data-row{
		.blog-list{
			max-width: 75%;
		}
		
		h2{
			max-width: 75%;	
		}
	}
}