@import "mixins";

body{
	font-family: $base-font;
	font-size: $base-size;
	color: $base-color;
}

.float-left{
	float: left;
}

.float-right{
	float: right;
}

ul{
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.row-flex,
.row-flex > div[class*='col-'] {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex:1 1 auto;
}
.row-flex-wrap {
    -webkit-flex-flow: row wrap;
    align-content: flex-start;
    flex:0;
}
.row-flex > div[class*='col-'],
.container-flex > div[class*='col-'] {
    margin:-.0px;
}
.container-flex > div[class*='col-'] div,
.row-flex > div[class*='col-'] div {
    width:100%;
}
.flex-col {
    display: flex;
    display: -webkit-flex;
    flex: 1 100%;
    flex-flow: column nowrap;
}
.flex-grow {
    display: flex;
    -webkit-flex: 2;
    flex: 2;
}

.horizontal-center{
	-webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
	 left: 50%;
}

.container{
	max-width: 1300px;
}

#wrapper{
	
}

.middle{
	padding: 70px 0 0 0;

	@include md() {
		padding: 130px 0 0 0;
	}
}

h1, h2{
	width: 100%;
	float: left;
	font-size: 24px;
	font-weight: 700;
}

h3{
	width: 100%;
	float: left;
	font-size: 40px;
	font-weight: 400;
}

p{
	width: 100%;
	float: left;
}

.accordion,
.accordion-button,
.accordion-item,
.accordion-body,
.accordion-collapse{
	width: 100%;
	float: left;
}

.accordion-button:not(.collapsed){
	background: url("#{$images-path}/heart.svg") no-repeat 24px center;
	color: $base-color;
	border: none;
	box-shadow: none;
}

.accordion-button:focus{
	box-shadow: none;
}

.accordion-button{
	background: none;
	padding: 20px 24px 20px 73px;
	background: url("#{$images-path}/heart.svg") no-repeat 24px center;
	color: $base-color;
	font-size: $base-size;
	
	&:after{
		display: none;
	}
}

.accordion-body{
	padding: 0 24px 0 73px; 
	
	p{
		padding: 0 0 24px;
		margin: 0;
		text-align: left !important;
	}
}

.accordion-item{
	margin: 8px 0;
	border: 1px solid #EAEAEA !important;
	border-radius: 16px !important;
	padding: 0;
}

.form-group{
	padding: 24px 0 0 0;
}

label{
	color: #6D6D78;
	font-weight: 400;
	font-size: 16px;
}

.form-control,
.form-select{
	border: 1px solid #D5D4D4;
	border-radius: 20px;
	color: $base-color;
	font-weight: 600;
	font-size: 20px;
	padding: 20px 15px;
	
	&:focus{
		border: 1px solid $blue-color;
		box-shadow: none;
	}
	
	&::-webkit-input-placeholder{
	  	color: #ababb2 !important;
		opacity: 1;
		font-weight: 400;
	}

	&:-ms-input-placeholder{
		color: #ababb2 !important;
		opacity: 1;
		font-weight: 400;
	}

	&::placeholder{
		color: #ababb2 !important;
		opacity: 1;
		font-weight: 400;
	}
}

.input-box{
	&.with-icon{
		position: relative;
		.form-control,
		.form-select{
			padding-left: 68px;
		}
	}

	.invalid-feedback-error, em {
		color: #721c24;
		font-size: 12px;
		font-style: normal;
	}

	i{
		width: 32px;
		height: 32px;
		float: left;
		position: absolute;
		left: 16px;
		top: 21px;
		z-index: 1;
		
		&.form-icon-1{
			background: url("#{$images-path}/icon-user.svg") no-repeat left top/32px;
			
		}
		
		&.form-icon-2{
			background: url("#{$images-path}/icon-photo.svg") no-repeat left top/32px;
			
		}
		
		&.form-icon-3{
			background: url("#{$images-path}/icon-sted.svg") no-repeat left top/32px;
			
		}
		
		&.form-icon-4{
			background: url("#{$images-path}/icon-heart.svg") no-repeat left top/32px;
		}
		
		&.form-icon-5{
			background: url("#{$images-path}/icon-chat.svg") no-repeat left top/32px;
		}
		
		&.form-icon-6{
			background: url("#{$images-path}/icon-email.svg") no-repeat left top/32px;
		}
		
		&.form-icon-7{
			background: url("#{$images-path}/icon-notes.svg") no-repeat left top/32px;
		}
	}
}

.column-view{
	padding-top: 30px;

	@include md() {
		padding-top: 0;
	}
}

.btn-row{
	padding: 24px 0 0;
	
	&.extra-pad{
		padding: 60px 0 0 0;
	}
}

.blue-btn{
	background: $blue-color;
	color: $base-color;
	font-size: 16px;
	font-weight: 600;
	border: none;
	text-decoration: none;
	display: inline-block;
	text-align: center;
	border-radius: 20px;
	padding: 20px 0;
	position: relative;

	@include md() {
		font-size: 20px;
	}
	
	&:hover{
		background: $pink-color;
		color: $base-color;
	}
	
	img, svg{
		margin: 0 24px 0 0;
		float: right;
		position: relative;
		top: 8px;
	}
}

.multiple-buttons {
	width: 100%;

	&.green-btn {
		max-width: 450px;
	}

	@include md() {
		max-width: 400px;
	}
}

.green-btn{
	background: #5AE2E2;
	color: $base-color;
	font-size: 16px;
	font-weight: 600;
	border: none;
	text-decoration: none;
	display: inline-block;
	text-align: center;
	border-radius: 20px;
	padding: 20px 0;
	position: relative;

	@include md() {
		font-size: 20px;
	}
	
	&:hover{
		background: $pink-color;
		color: $base-color;
	}
	
	img{
		margin: 0 24px 0 0;
		float: right;
		position: relative;
		top: 8px;
	}
}

.pink-btn{
	background: #DD5471;
	color: $white-color;
	font-size: 16px;
	font-weight: 600;
	border: none;
	text-decoration: none;
	display: inline-block;
	text-align: center;
	border-radius: 20px;
	padding: 20px 0;
	position: relative;
	text-transform: uppercase;
	
	&:hover{
		background: $pink-color;
		color: $base-color;
	}
	
	img{
		margin: 0 24px 0 0;
		float: right;
		position: relative;
		top: 8px;
	}
}

.fancy-radio{
	padding: 40px 0 0 0;
	.btn{
		background: none;
		border: none;
		font-size: 20px;
		color: $base-color;
		padding: 0;
		
		&:hover{
			color: $base-color;
		}
	}
	
	.circle{
		width: 36px;
		height: 36px;
		border: 1px solid #EAEAEA;
		border-radius: 50%;
		display: inline-block;
		vertical-align: middle;
		margin-right: 40px;
		position: relative;
		
	}
	
	.btn-check:checked + .btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check) + .btn:active{
		background: none;
		color: $base-color;
		
		.circle{
			&:after{
				width: 100%;
				float: left;
				text-align: center;
				color: $base-color;
				font-weight: 700;
				font-size: 18px;
				content: "X";
				position: absolute;
				left: 0;
				top: 3px;
			}
		}
	}
}

.cta-section{
	padding: 93px 0 0 0;
	
	.container{
		max-width: 1000px;
	}
	
	.cta-title{
		font-size: 50px;
		font-weight: 700;
		line-height: 56px;

		@include md() {
			font-size: 72px;
			line-height: 78px;
		}
	}
	
	p{
		max-width: 590px;
		line-height: 28px;
		-webkit-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		transform: translateX(-50%);
		 left: 50%;
		position: relative;
		padding: 40px 0 0 0;
		color: #594D6D;
	}
	
	.cta-btn{
		max-width: 435px;
		position: relative;
		padding: 30px 0 0 0;
		
		a,
		button{
			background: url("#{$images-path}/arrow-white.svg") no-repeat right 30px center $pink-dark-color;
			color: $white-color;
			border: none;
			border-radius: 28px;
			padding: 17px 0;
			font-size: 16px; 
			font-weight: 700;
			text-decoration: none;
			text-transform: uppercase;
			
			&:hover{
				background: url("#{$images-path}/arrow-white.svg") no-repeat right 30px center $base-color;
				color: $white-color;
			}
		}
	}
}

.dropdown-menu-arrow {
	top: -26px;
	left: 40px;
	width: 0;
	height: 0;
	position: relative;
}
.dropdown-menu-arrow:before,
.dropdown-menu-arrow:after {
	content: "";
	position: absolute;
	display: block;
	width: 0;
	height: 0;
	border-width: 7px 8px;
	border-style: solid;
	border-color: transparent;
	z-index: 1001;
}
.dropdown-menu-arrow:after {
	bottom: -18px;
	right: -8px;
	border-bottom-color: #fff;
}
.dropdown-menu-arrow:before {
	bottom: -17px;
	right: -8px;
	border-bottom-color: rgba(0,0,0,.25);
}

.fancy-checkbox-row{
	width: auto;
	float: right;

	.dropdown-menu {
		top: 10px !important;
		font-size: 14px;

		a {
			color: #6D6D78;
			text-decoration: none;

			&:hover {
				color: #172239;
			}
		}
	}
		
	.btn{
		width: 80px;
		height: 130px;
		border-radius: 60px !important;
		position: relative;
		border: 2px solid #EAEAEA !important;
		margin: 0 0 0 23px !important;
		float: left;
		position: relative;
		padding: 67px 0 0 0;
		text-align: center;
		color: #6D6D78;
		font-size: 14px;
		background: none !important;

		&:after {
			display: none !important;
		}

		.circle{
			width: 60px;
			height: 60px;
			float: left;
			position: absolute;
			top: 10px;
			left: 8px;
			border-radius: 50%;
		}

		&.share {
			.circle {
				background: url("#{$images-path}/icon-share-white.svg") no-repeat center;
				background-color: #D5D4D4;
				border: 2px solid #D5D4D4;
			}
		}

		&.love {
			.circle {
				background: url("#{$images-path}/icon-heart-white.svg") no-repeat center;
				background-color: #D5D4D4;
				border: 2px solid #D5D4D4;
			}
		}

		&.report {
			.circle {
				background: url("#{$images-path}/icon-report-black.svg") no-repeat center;
				border: 2px solid #D5D4D4;
			}
		}

		&:hover {

			.text {
				color: #172239;
			}

			&.share {
				border: 2px solid #52C3FF !important;

				.circle {
					background-color: #52C3FF;
					border: 2px solid #52C3FF;
				}
			}

			&.love {
				border: 2px solid #FC77A0!important;;

				.circle {
					background-color: #FC77A0;
					border: 2px solid #FC77A0;
				}
			}

			&.report {
				.circle {
					background: url("#{$images-path}/icon-report-black.svg") no-repeat center;
					border: 2px solid #D5D4D4;
				}
			}
		}
	}
}
.react-datepicker-popper {
	z-index: 2 !important;
}

//.react-datepicker--time-only {
//	.react-datepicker__triangle {
//		&:after, &:before {
//			left: -43px !important;
//		}
//	}
//}
.react-datepicker-wrapper {
	width: 100%;
}