@import 'variable';
@import 'mixins';

.step-container{
	position: relative;

	.funeral-information {
		@include md() {
			padding: 40px 40px 100px;
		}

		.funeral-information-title {
			@include md() {
				padding: 8px 0 8px 116px;
				font-size: 40px;
				line-height: 48px;
			}
		}

		.funeral-info-data {
			@include md() {
				padding: 28px 0 0 116px;
			}
		}
	}

	.step-left{
		background: $pink-color;
		border-radius: 64px;
		padding: 0 0 60px;
		margin: 66px 0 60px 0;

		@include lg() {
			padding: 30px 0 150px;
			margin: 186px 0 0 0;
		}

		&.less-pad{
			@include lg() {
				margin: 64px 0 0 0;
			}
		}

		&.with-shadow-border{
			box-shadow: 0 4px 4px rgba(0,0,0,0.1);
			border: 5px solid $white-color;

			@include lg() {
				margin: 140px 0 0 0;
			}
		}
	}

	.info-box{
		padding: 60px 0 0 0;

		@include lg() {
			padding: 86px 0 0 0;
		}

		span{
			font-size: 24px;
			color: #6D6D78;
			padding: 10px 0 0 0;
		}
	}

	.step-right{
		padding-top: 64px;
		position: relative;

		@include lg() {
			padding-bottom: 60px;
		}

		p{
			font-size: 16px;
			font-weight: 500;
			text-align: center;
		}
	}

	h1{
		font-size: 40px;
		text-align: center;
	}

	h3{
		font-size: 40px;
		font-weight: 400;

		@include lg() {
			padding: 70px 0 0 0;
		}
	}

	.back-btn {
		width: 100%;
		background: none;
		border: none;
		font-size: 16px;
		margin-top: 20px;

		@include md() {
			width: auto;
		}
	}

	.back-arrow{
		position: absolute;
		left: 15px;
		top: 20px;
		width: 48px;
		float: left;
		height: 48px;
		border: none;
		padding: 0;
		background: url("#{$images-path}/back-arrow.svg") no-repeat left top/48px;
		z-index: 9;

		@include lg() {
			left: 15px;
			top: 62px;
		}
	}

	.payment-icon{
		padding: 15px 50px 0 0;

		img {
			padding-right: 15px;
		}
	}

	.circle-radio{
		.btn{
			width: 40px;
			height: 40px;
			border: 1px solid #5AE2E2;
			border-radius: 50% !important;
			font-size: 13px;
			font-weight: 600;
			color: #6D6D78;
			padding: 9px 0 0 0;
			text-align: center;
			margin: 0 8px;

			&.pink-border{
				border: 1px solid #FC77A0;
			}
		}

		.btn-check:checked + .btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check) + .btn:active{
			background: $pink-color
		}
	}

	.option-selection{
		.btn{
			width: 100%;
			float: left;
			border: 1px solid #D5D4D4 !important;
			border-radius: 20px !important;
			padding: 24px 24px 24px 76px;
			text-align: left;
			font-weight: 600;
			color: $base-color;
			margin: 10px 0 0 0 !important;
			position: relative;

			.price{
				float: right;
				font-weight: 400;
			}

			.circle{
				width: 32px;
				height: 32px;
				float: left;
				border: 1px solid #D5D4D4;
				border-radius: 50%;
				position: absolute;
				left: 24px;
				top: 50%;
				margin: -16px 0 0;

			}
		}

		.btn:hover {
			border: 1px solid #52C3FF !important;
			background: none;
			color: $base-color;
		}

		.btn-check:checked + .btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check) + .btn:active{
			border: 1px solid #52C3FF !important;
			background: none;
			color: $base-color;

			.circle{
				background: url("#{$images-path}/icon-check.svg") no-repeat center #52C3FF;
				border: 1px solid #52C3FF;
			}
		}
	}

	.step-3-info{
		max-width: 340px;
		position: relative;

		ul{
			li{
				padding: 60px 0 0 0;
				width: 100%;
				float: left;


				.title{
					font-weight: 600;

					span{
						border-bottom: 2px solid $base-color;
					}
				}

				p{
					padding: 5px 0 0 0;
					font-size: 16px;
					margin: 0;
					line-height: 26px;
				}
			}
		}
	}

	.payment-img{
		padding: 46px 0 0 0;
	}

	.payment-title{
		max-width: 336px;
		position: relative;
		font-weight: 600;
		font-size: 40px;
		padding: 43px 0 0 0;
		line-height: 45px;
	}

	.small-title{
		max-width: 402px;
		position: relative;
		padding: 92px 0 0 0;
		clear: both;
	}


	.step-2-left-info{
		padding: 40px 15px 60px;

		@include lg() {
			padding: 0 40px 0;
		}

		ul{
			li{
				width: 100%;
				float: left;
				padding: 30px 0 0 0;
				white-space: pre-wrap;
				word-break: keep-all;
				overflow-wrap: break-word;

				.large-text{
					font-weight: 700;
					font-size: 26px;
					line-height: 50px;
				}
			}
		}
	}

	.left-icon-title{
		font-size: 40px;
		font-weight: 600;
		padding: 60px 0 0 0;
		text-transform: uppercase;
	}

	.form-group{
		.dropdown-toggle{
			border: 1px solid #D5D4D4;
			border-radius: 20px;
			color: $base-color;
			font-weight: 600;
			font-size: 20px;
			padding: 20px 60px 20px 68px;
			background: none;
			text-align: left;

			&:after{
				border: none;
				width: 32px;
				height: 32px;
				float: left;
				position: absolute;
				right: 15px;
				top: 20px;
				background: url("#{$images-path}/arrow-down-circle.svg") no-repeat left top/32px;
				content: "";
			}
		}

		.dropdown-menu{
			padding: 15px;
			border: 1px solid #D5D4D4;
			border-radius: 20px;
			max-height: 375px;
			overflow-y: scroll;
		}
	}

}

.step-full-height-container{
	position: relative;
	padding: 0 0 50px;

	&:after{
		@include lg() {
			width: 50%;
			float: left;
			height: 100%;
			background: $pink-color;
			content: "";
			position: absolute;
			left: 0;
			top: 0;
		}
	}

	.left-icon-title{
		@include lg() {
			padding: 80px 0 0 50px;
		}
	}

	.container{
		position: relative;
		z-index: 1;
	}
}