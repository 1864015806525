$base-color: #172239;
$pink-color: #FFDFD6;
$pink-dark-color: #FF6498;
$white-color: #fff;
$blue-color: #52C3FF;

$base-font: 'DM Sans', sans-serif;

$base-size: 20px;

$images-path: "/assets/images";
