@import "../variable";
@import "../mixins";

header{
	padding: 20px 0 0 0;

	@include lg() {
		padding: 36px 0 0 0;
	}
	
	#header-menu-btn{
		border: none; 
		outline: none; 
		padding: 15px 0 0 0; 
		position: absolute; 
		z-index: 1000; 
		width: 35px; 
		right: 0; 
		top: 5px; 
		box-shadow: none;
		
		.icon-bar{
			background: $base-color;;
			transform: rotate(0deg) translate(0px, 0px);
			transition: ease all 0.2s; 
			display: block; 
			width: 35px; 
			height: 4px; 
			border-radius: 13px;
		
			&:nth-child(2){
				width: 35px; 
				transition: ease all 0.2s;
			}
			
			&:hover > .icon-bar:nth-child(2){
				width: 35px; 
				transition: ease all 0.2s;
			}
			
			&:active > .icon-bar:nth-child(2){
				width: 35px; 
				transition: ease all 0.2s;
			}
			
			&:not(.collapsed) .icon-bar:nth-child(1){
				transform: rotate(45deg) translate(8px, 7px); 
				transition: ease all 0.2s;
			}
			
			&:not(.collapsed) .icon-bar:nth-child(2){
				opacity: 0; 
				transition: ease all 0.2s;
			}
			
			&:not(.collapsed) .icon-bar:nth-child(3){
				transform: rotate(-45deg) translate(8px, -6px); 
				transition: ease all 0.2s;}
		}
		
		.icon-bar + .icon-bar{
			margin-top: 6px;
		}
		
		.nice-select.open > .icon-bar:nth-child(2){
			width: 35px; 
			transition: ease all 0.2s;
		}
	}
	
	.bg-body-tertiary{
		background: none !important; 
		
	}
	
	.navbar-brand{
		img{
			max-height: 29px;
		}
	}
	
	.navbar-nav{
		li{
			width: 100%;
			float: left;
			padding: 10px 0 0 0;

			@include lg() {
				padding: 0 0 0 10px;
				width: auto;
			}

			@include xl() {
				padding: 0 0 0 20px;
			}
				
			a{
				background: #f8f8f8;
				border-radius: 20px;
				padding: 14px 20px !important;
				color: $base-color;
				float: left;
				width: 100%;
				text-decoration: none; 
				text-align: center;
				font-weight: 600;
				font-size: 16px;

				@include lg() {
					width: auto;
					padding: 14px 10px;
				}

				@include xl() {
					padding: 14px 20px;
				}
				
				img{
					margin: 0 7px 0 0;
					position: relative;
					top: -2px;
				}
				
				&:hover{
					color: $base-color;
					background: $pink-color;
					text-decoration: none;
				}
			}
			
			&.last-btn{

				@include lg() {
					padding: 0 0 0 15px;
				}

				@include xl() {
					padding: 0 0 0 40px;
				}

				a{
					background: $pink-color !important;
				}
			}
		}
	}
}

.banner {
	width: 100%;
	float: left;
	padding: 45px 0 0;
	background: #f8f8f8;
	margin-top: 15px;

	@include md() {
		background: none;
		margin-top: 0px;
	}

	.banner-image {
		@include md() {
			background: url("#{$images-path}/banner.svg") no-repeat right bottom !important;
		}
	}

	.banner-content{
		float: left;
		position: relative;

		@include md(){
			max-width: 652px;
		}

		h3 {
			font-size: 40px;
			font-weight: 600;
		}
	}

	.banner-btn {
		width: 100%;
		height: 60px;
		padding: 16px 48px 16px 48px;
		border-radius: 8px;
		opacity: 0.8799999952316284;
		background: #FFDFD6;
		color: $base-color;
		text-decoration: none;
		font-weight: 700;
		text-align: center;
		margin-top: 20px;

		@include md() {
			max-width: 400px;
		}
	}

	p{
		width: 100%;
		float: left;;
		font-size: 20px;
		padding: 30px 0 20px 0;
	}

	.banner-left-bottom{
		width: auto;
		float: left;
		padding: 20px 0 0 0;
	}

	.banner-rating{
		width: 100%;
		float: left;
		padding: 18px 0 0 0;
		font-size: 16px;
		text-align: center;

		@include md() {
			max-width: 400px;
		}

		img{
			width: 220px;
			margin-top: -25px;
		}
	}
}