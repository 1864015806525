@import 'variable';
@import 'mixins';

.contact-container{
	background: url("#{$images-path}/pink-bg-2.png") no-repeat center -120px;
	position: relative;
	
	.contact-top{
		background: $white-color;
		position: relative;
		padding: 50px 0 0 0;

		&:after {
			@include lg() {
				width: 454px;
				float: left;
				position: absolute;
				right: 0;
				top: 0;
				background: $pink-color;
				height: 100%;
				border-radius: 0 50px 50px 0;
				content: "";
			}
		}

		@include lg() {
			box-shadow: 0 200px 141px -80px rgba(25,58,75,0.3);
			border-radius: 50px;
			padding: 100px 0;
			margin-bottom: 200px;
		}
		
		.container{
			position: relative;
			z-index: 1;
		}
		
		.contact-left{
			padding-top: 0px;
		}
		
		h1{
			font-size: 36px; 
			font-weight: 700;

			@include lg() {
				font-size: 54px;
			}
			
			span{
				color: #DD5471; 
			}
		}
		
		p{
			width: 100%;
			float: left;
			font-weight: 600;
			font-size: 14px;
			padding: 15px 0 0 0;
			margin: 0;
		}
		
		.contact-detail-row{
			padding: 30px 0 60px 0;

			@include lg() {
				padding: 30px 0 0 0;
			}
		}
		
		.contact-box{
			padding: 0 0 0 43px;
			margin: 30px 0 0;
			position: relative;
			color: $base-color;
			font-weight: 500;
			font-size: 14px;
			
			img, svg{
				position: absolute;
				left: 0;
				top: 50%;
				width: 28px;
				margin: -14px 0 0 0;
				
			}
			
			span,
			a{
				color: #DD5471;
				text-decoration: none;
				font-weight: 400;
				
				&:hover{
					color: $base-color;
					text-decoration: none;
				}
			}
		}
		
		.contact-map{
			
		}
	}
}