$screen-xxs-min: 360px;
$screen-xs-min: 576px;
$screen-sm-min: 640px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1280px;
$screen-xxl-min: 1360px;
$screen-xxxl-min: 2200px;

@mixin xxs {
    @media (min-width: #{$screen-xxs-min}) {
        @content;
    }
}

@mixin xs {
    @media (min-width: #{$screen-sm-min}) {
        @content;
    }
}

@mixin sm {
    @media (min-width: #{$screen-sm-min}) {
        @content;
    }
}

@mixin md {
    @media (min-width: #{$screen-md-min}) {
        @content;
    }
}

@mixin lg {
    @media (min-width: #{$screen-lg-min}) {
        @content;
    }
}

@mixin xl {
    @media (min-width: #{$screen-xl-min}) {
        @content;
    }
}

@mixin xxl {
    @media (min-width: #{$screen-xxl-min}) {
        @content;
    }
}

@mixin xxxl {
    @media (min-width: #{$screen-xxxl-min}) {
        @content;
    }
}
